import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const maintenanceApi = createApi({
  reducerPath: "maintenanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: ["maintenance"],
  endpoints: (builder) => ({
    getMaintenanceDueTransactionList: builder.query<any, any>({
      query: (data:any) => ({
        url: "/maintenance/",
        method: "GET",
        params:{
            year:data.year
        }
      }),
      providesTags: ["maintenance"],
    }),
    initiateMaintenancePayment: builder.mutation<any,any>({
      query: (data) => ({
          url: `/maintenance/Maintainence_payment_gateway/`,
          method: "POST",
          body:data
      }),
      invalidatesTags: ['maintenance']
  }),
    
   
  }),
});

export const { useGetMaintenanceDueTransactionListQuery , useInitiateMaintenancePaymentMutation } =maintenanceApi;