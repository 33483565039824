
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const myAccountApi: any = createApi({
  reducerPath: "myAccount",
  baseQuery: fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers: any) => {
      headers.set("Authorization", `Token ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: ["myAccount"],
  endpoints: (builder) => ({
    getCustomerDetail: builder.query<any, any>({
      query: () => ({
        url: "/customer-profile/",
        method: "GET",
      }),
      providesTags: ["myAccount"],
    }),
    editProfileInfo: builder.mutation<any, {profileInfo:any;id:any}>({
      query: ({profileInfo,id}) => ({
        url: `/account-information/${id}/`,
        method: "PATCH",
        body: profileInfo,
      }),
      invalidatesTags: ["myAccount"],
    }),
    switchUnitNo:builder.mutation<any,any>({
      query: (unitObj) => ({
        url: "/customer-active-unit/setcustomerunit/",
        method: "POST",
        body:unitObj
      }),
      invalidatesTags: ["myAccount"],
    })
  }),
});

export const { useGetCustomerDetailQuery, useEditProfileInfoMutation,useSwitchUnitNoMutation } =myAccountApi;