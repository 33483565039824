import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";

export const customerEnrichApi = createApi({
    reducerPath: "customerEnrich",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['community-enrich', 'enrichments'],
    endpoints: (builder) => ({
        getAllEnrichProductsData: builder.query<any, { applyCategories: string[] }>({
            query: ({ applyCategories }) => ({
                url: "/enrich-product-list/",
                method: "GET",
                params: {
                    categories: applyCategories.join(','),
                    show_all: "True"
                },
            }),
            providesTags: ['enrichments']
        }),
   
        getAllEnrichProducts: builder.query<any, { applyCategories: string[]; pages: number }>({
            query: ({ applyCategories, pages }) => ({
                url: "/enrich-product-list/",
                method: "GET",
                params: {
                    categories: applyCategories.join(','),
                    page: String(pages),
                },
            }),
        }),
        getOfferZone: builder.query<any, void>({
            query: () => ({
                url: "/offer-zones/",
                method: "GET",
            }),
        }),
        getOfferZoneShop: builder.query<any, {shopId:any ,pages:any}>({
            query: ({shopId}) => ({
                url: `/offer-zones/get_offerzone_products/`,
                method: "GET",
                params:{
                    shop_code:shopId
                }
            }),
        }),
        getAdvertisementList: builder.query<any,void>({
            query: () => ({
                url: `vendor-advertisement/`,
                method: "GET",
                params:{
                    customer:1,
                }
            }),
        }),
    }),
});

export const {
    useGetAllEnrichProductsDataQuery,
    useGetAllEnrichProductsQuery,
    // ,useMyEnquireListQuery,
    useGetOfferZoneQuery,
    useGetOfferZoneShopQuery,
    useGetAdvertisementListQuery,
} = customerEnrichApi;



